<div *ngIf="toolsService.showDeckValidator">    
    <div style="text-align: center;" *ngIf="!deckList">
        <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
        <span class="sr-only">Loading...</span>
    </div>

    <div *ngIf="deckList">
        <div class="table-header ui-fluid">
            <strong>Deck List</strong>
            <p-message [severity]="isDeckValid ? 'success' : 'error'" [style]="{'width':'100%', 'text-align': 'center', 'margin-bottom': '0.5rem'}"
                [text]="isDeckValid ? 'Deck appears to be legal' : 'Deck contains invalid cards'">
            </p-message>
        </div>

        <!--
        <div *ngFor="let card of deckList"> 
            <div *ngIf="card" class="card-row"> 
                <i [class]="card.Legal ? 'fa fa-check-circle text-success' : 'fa fa-times-circle text-danger'" aria-hidden="true" ></i>
                <span style="width: 100%;"> {{ card.Name }} </span>
                
                <span style="float:right; text-align: right; vertical-align: middle; padding-top: 5px;">
                    ${{ card.Price.toFixed(2) }}
                    <i class="fa fa-picture-o hoverable" aria-hidden="true" ></i>                    
                    <img src="{{ card.ImageUrl }}" class="show-on-hover popup-img" width="146" height="204">                    
                </span>
            </div>
            <div *ngIf="card == null" class="card-row">
                <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true" ></i>
                card not found 
            </div>
        </div>
        -->
        <div *ngFor="let card of deckList;let i = index" class="ui-fluid">
            <app-deck-editor-entry *ngIf="card" [card]="card">
            </app-deck-editor-entry>

            <p-message [style]="{'width':'100%', 'text-align': 'center', 'margin': '0.5rem'}"
                [text]="'Unable to find card: ' + requestList[i]" severity="error"
                *ngIf="!card"> 
        </p-message>
        </div>
    </div>

</div>

<p-dialog [(visible)]="toolsService.showDeckInputDialog" [draggable]="false" [modal]="true" [dismissableMask]="true" 
    [closable]="true" header="Deck List Validator" [blockScroll]="true" 
    (onShow)="onShowDeckInput($event)" (onHide)="onHideDeckInput($event)">

    <div class="" style="text-align: center;">
        <textarea [rows]="25" [cols]="50" pInputTextarea [autoResize]="false"
            [(ngModel)]="deckListText">
        </textarea>
    </div>
    <span class="wide-p-button clearfix" style="color: lightgray; margin: 0; padding: 0;">
        <div style="margin: 1rem;">
            <p-button (click)="onCheckDeck()" label="Check Deck List" icon="fa fa-thumbs-up" 
                class="ui-button" [style]="{'width':'100%'}">
            </p-button>
        </div>
    </span>


</p-dialog>
