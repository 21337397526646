<p-dialog [(visible)]="toolsService.showCardDetails" [draggable]="false" [modal]="true" [dismissableMask]="true" 
    [closable]="true" [responsive]="false"  [blockScroll]="true" 
    positionTop="100" (onShow)="onShowCardDetails($event)" (onHide)="onHideCardDetails($event)"
    [header]="card != null ? card.Name : 'Card Details'"
    >
        <!--
    <p-header>

        <i aria-hidden="true" 
            [class]="deckBuilderService.CardIsInDeck(card) ? 'fa fa-lg link-icon fa-star' : 'fa fa-lg link-icon fa-star-o'"
            (click)="onDeckListClick(card)">
        </i>
     
        {{ card != null ? card.Name : 'Card Details' }}
    </p-header>
   -->
    

    <div style="max-width: 30rem; max-height: 75vh;">

        <div *ngIf="!card" style="text-align: center;" >
            <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
            <span class="sr-only">Loading...</span>
        </div>

        <div class="p-grid" style="overflow-y: scroll;" *ngIf="card">
            <div class="p-col-12 ui-fluid legal-bar">
                <p-message [severity]="card.Legal ? 'success' : 'error'" [style]="{'width':'100%', 'text-align': 'center'}"
                    [text]="card.Legal ? 'Card appears to be legal' : 'Card is not legal for this format'">
                </p-message>
            </div>
            <div class="p-col-12 p-md-6 ui-fluid">
                <img src="{{ card.ImageUrlLarge }}" style="max-width: 100%;">
            </div>
            <div class="p-col-12 p-md-6 ui-fluid">
                <div>
                    <strong>{{ card.Name }}</strong>
                    <span style="float: right;"> 
                        <span [innerHTML]="iconifyText(card.ManaCost)"> </span> 
                        <br>
                        <span style="float: right;" *ngIf="card.Price != null">${{ card.Price.toFixed(2) }}</span>
                    </span> 
                </div>
                <span><em>{{ card.SubType ? card.Type + " - " + card.SubType : card.Type }}</em></span><br>
                <span style="white-space: pre-wrap;" [innerHTML]="iconifyText(card.OracleText)"></span>                
                <div *ngIf="card.Power != null || card.Toughness != null"> 
                    <strong>{{ card.Power }} / {{ card.Toughness }} </strong>                         
                </div>
            </div>

            <div *ngIf="comboCount() != 0" class="p-col-12 ui-fluid">
                <p-panel header="Combos ({{comboCount()}})" [toggleable]="true" (onBeforeToggle)="onLoadCombos()" [(collapsed)]="hideCombos">
                    <div style="text-align: center;" *ngIf="!ComboData">
                        <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="p-grid" *ngIf="ComboData != null">
                        <app-card-combo *ngFor="let combo of ComboData.Combos" 
                            [Combo]="combo" [Cards]="ComboData.Cards"
                            (CardClicked)="onCardClicked($event)">
                        </app-card-combo>
                    </div>
                </p-panel>
            </div>

            <div class="p-col-12 ui-fluid">
                <p-panel header="Rulings" [toggleable]="true" (onBeforeToggle)="onLoadRulings()" [(collapsed)]="hideRulings">
                    <div style="text-align: center;" *ngIf="!rulings">
                        <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="p-grid" *ngIf="rulings != null">
                        <div *ngFor="let ruling of rulings" class="p-col-12">
                            <span style="white-space: pre-wrap;" [innerHTML]="iconifyText(ruling.comment)"></span> 
                            <br>
                            <em> {{ ruling.published_at }} </em>
                        </div>
                        <div class="p-col-12" *ngIf="rulings.length === 0">
                            <em> No rulings found for {{ card.Name }}</em>
                        </div>
                    </div>
                    
                </p-panel>
            </div>


        </div>

    </div>

</p-dialog>