<div style="width: 100%;">
    <table style="width: 100%;">
        <tr>
            <td width="4rem;">            
                <div class="ui-inputgroup">
                    <span class="ui-inputgroup-addon" style="padding-right: 0.75rem;">{{ label }}</span>
                </div>
            </td>
            <td style="width: 2rem; text-align: center;">{{ range[0] }}</td>
            <td style="width: auto;">
                <p-slider [(ngModel)]="range" [range]="true" [min]="min" [max]="max" (onChange)="onSliderChange($event)">
                </p-slider>
            </td>
            <td style="width: 2rem; text-align: center;">{{ range[1] }}</td>
        </tr>
    </table>
</div>