import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DatabaseService } from '../database.service';
import { Outcome, MatchResult } from '../model/Match';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-leaderboard-cell',
  templateUrl: './leaderboard-cell.component.html',
  styleUrls: ['./leaderboard-cell.component.css']
})
export class LeaderboardCellComponent {

  @Input() matchResult: MatchResult;

  constructor() {
  }

  getStyleForResult(): string {
    if ( this.matchResult == null ) {
      return 'result-none';
    } else if ( this.matchResult.matchResult() === Outcome.Player1Win ) {
      return 'result-win';
    } else if ( this.matchResult.matchResult() === Outcome.Player2Win ) {
      return 'result-loss';
    }
    return 'result-draw';
  }

  record() {
    if (this.matchResult == null) {
      return '';
    }

    const wins = this.matchResult.gameResults.filter( gr => gr === Outcome.Player1Win ).length;
    const losses = this.matchResult.gameResults.filter( gr => gr === Outcome.Player2Win ).length;
    const draws = this.matchResult.gameResults.filter( gr => gr === Outcome.Draw ).length;

    let result = `${wins} - ${losses}`;
    if (draws !== 0) {
      result += ` - ${draws}`;
    }

    return result;
  }
}
