import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DatabaseService } from '../database.service';
import { MatchResult, Outcome, IMatchResult } from '../model/Match';


@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.css']
})
export class LeaderboardComponent {

  players: string[] = ['Ben', 'Eddie', 'Jason', 'Jeff', 'Jeremy', 'Joe'];
  // players: string[] = ['Jason', 'Jeff', 'Joe'];
  matches: MatchResult[] = [];

  constructor(public databaseService: DatabaseService) {
    // this.matches.push( new MatchResult('Jeff', 'Joe', Outcome.Player1Win, Outcome.Player1Win) );
    // this.matches.push( new MatchResult('Jeff', 'Jason', Outcome.Player1Win, Outcome.Player2Win, Outcome.Player1Win) );
    this.databaseService.GetSeasonStandings()
      .subscribe( results => this.readRawData(results) );
  }

  readRawData( results: IMatchResult[] ) {
    this.matches.length = 0;
    results.forEach( result =>
      this.matches.push( new MatchResult( result.player1, result.player2, ...result.gameResults ) ) );

  }

  getMatchResult(player1: string, player2: string): MatchResult {
    const result = this.matches
      .filter( mr => (mr.player1 === player1 || mr.player1 === player2) && (mr.player2 === player1 || mr.player2 === player2) );

    return result.length === 0 ? null : (result[0].player1 === player1 ? result[0] : result[0].invertPlayers() );
  }

  record(player: string) {
    const matches = this.matches
      .filter( mr => mr.player1 === player || mr.player2 === player)
      .map( mr => mr.player1 === player ? mr : mr.invertPlayers() );

    const wins = matches.filter( mr => mr.matchResult() === Outcome.Player1Win ).length;
    const losses = matches.filter( mr => mr.matchResult() === Outcome.Player2Win ).length;
    const draws = matches.filter( mr => mr.matchResult() === Outcome.Draw ).length;

    let result = `${wins} - ${losses}`;
    if (draws !== 0) {
      result += ` - ${draws}`;
    }

    return result;
  }
}

