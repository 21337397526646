import { Component, Input } from '@angular/core';
import { DatabaseService } from '../database.service';
import { Card } from '../model/Card';
import { ToolsService } from './tools.service';
import { DbResult } from '../model/DbResult';
import { Utils } from '../model/Utils';
import { PopupCardList } from './popup-card-list.base';

@Component({
  selector: 'app-uncosted-list',
  templateUrl: './popup-card-list.base.html',
  styleUrls: ['./popup-card-list.base.css']
})
export class UncostedListComponent extends PopupCardList {

    constructor(databaseService: DatabaseService, toolsService: ToolsService) {
      super(databaseService, toolsService);
      this.headerTitle = 'Unavailable Cards';
      this.headerText = 'The cards in this list have no prices listed in the database and therefore have been marked illegal by default.';
    }

    public onShowList(event: any) {
      this.databaseService.GetUncostedCards().subscribe( deckList => this.processDeckList(deckList) );
    }

}
