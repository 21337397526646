import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { DatabaseService } from '../database.service';
import { Observable, VirtualTimeScheduler } from 'rxjs';
import { DbResult } from '../model/DbResult';
import { Card } from '../model/Card';
import { QueryParams } from '../model/QueryParams';
import { MessageService, Message } from 'primeng/api';
import { ToolsService, Dialog } from './tools.service';
import { DeckBuilderService } from './deck-builder.service';
import { CardListComponent } from './card-list.component';


@Component({
  selector: 'app-browser',
  templateUrl: './browser.component.html',
  styleUrls: ['./browser.component.css']
})
export class BrowserComponent implements AfterViewInit {

    @ViewChild(CardListComponent) cardList: CardListComponent;

    queryParams: QueryParams;


    constructor(
        public databaseService: DatabaseService,
        public toolsService: ToolsService,
        public messageService: MessageService,
        public deckBuilderService: DeckBuilderService) {
      this.queryParams = new QueryParams();
      this.deckBuilderService.ReadFromCookie();
    }

    ngAfterViewInit(): void {
      this.onSearch();
    }

    onSearch() {
      this.cardList.runSearch();
    }

    public onReset() {
      this.queryParams = new QueryParams();
    }

    public onShowDeckInputDialog() {
      this.toolsService.showToolSidebar = false;
      this.toolsService.showDeckInputDialog = true;
    }

    public onShowUncostedListDialog() {
      this.toolsService.showToolSidebar = false;
      this.toolsService.showUncostedListDialog = true;
    }

    public onShowDelaListDialog() {
      this.toolsService.showToolSidebar = false;
      this.toolsService.showDelta3List = true;
    }


    public receiveData( data: DbResult<Card>): void {
      alert('error - receiveData()');
    }

    public onDeckCreateClick() {
      if (!this.toolsService.showDeckEditor) {
        this.deckBuilderService.CreateNewDeck('cookie');
        this.toolsService.showToolSidebar = false;
      }
    }

    public onViewDeckClick() {
      if (this.deckBuilderService.IsActiveDeck && !this.toolsService.showDeckEditor) {
        this.toolsService.showToolSidebar = false;
        this.toolsService.activeDialog = Dialog.DeckEditor;
      }
    }

    public onSaveDeckClick() {
      if (this.deckBuilderService.IsActiveDeck) {
        this.toolsService.showToolSidebar = false;

        this.deckBuilderService.WriteToCookie();
        this.deckBuilderService.SaveToFile();
      }
    }

    public onShowCardBrowser() {
      if (! this.toolsService.showCardBrowser) {
        this.toolsService.showToolSidebar = false;
        this.toolsService.activeDialog = Dialog.CardBrowser;
      }
    }

    public onShowLeaderboard() {
      if (! this.toolsService.showLeaderboard) {
        this.toolsService.showToolSidebar = false;
        this.toolsService.activeDialog = Dialog.Leaderboard;
      }
    }

    public uploadDeck(event) {
      const input = event.target;
      
      if (! this.toolsService.showCardBrowser) {
        const message: Message = {};
        message.severity = 'error';
        message.summary = 'Failed to upload deck file.';
        message.detail = 'Cannot upload deck while deck editor is open.';
        this.messageService.add(message);
      } else {
        for (var index = 0; index < input.files.length; index++) {
            const reader = new FileReader();
            reader.onload = () => {
                const serilized: string = <string>reader.result;
                const errorMessage = this.deckBuilderService.ReadFromString(serilized);
                if (errorMessage != null) {
                  const message: Message = {};
                  message.severity = 'error';
                  message.summary = 'Failed to upload deck file.';
                  message.detail = errorMessage;
                  this.messageService.add(message);
                }
            };
            reader.readAsText(input.files[index]);
            break;
          }

      }
      this.toolsService.showToolSidebar = false;
  }

  public nothing() {
    const message: Message = {};
    message.severity = 'info';
    message.summary = 'Unimplmented';
    message.detail = 'This button not yet implemented.';
    this.messageService.add(message);
  }
}
