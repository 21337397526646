import { Component, Input, Output, EventEmitter } from '@angular/core';
import { QueryParams } from '../model/QueryParams';
import { SelectItem } from 'primeng/api';

@Component({
    selector: 'app-query-builder',
    templateUrl: './queryBuilder.component.html',
    styleUrls: ['./queryBuilder.component.css']
  })
export class QueryBuilderComponent {

    // tslint:disable-next-line: no-output-on-prefix
    @Output() public onSearch = new EventEmitter();
    @Output() public onReset = new EventEmitter();

    private _queryParams: QueryParams;
    @Input()
    public get queryParams(): QueryParams {
        return this._queryParams;
    }
    public set queryParams(value: QueryParams) {
        this._queryParams = value;
    }

    orderOptions: SelectItem[] = [
        {
            label: 'Alphabetical',
            value: 'alpha',
            icon: 'fa-sort-alpha-down',
        },
        {
            label: 'Casting Cost',
            value: 'cmc',
            icon: 'ms ms-r ms-cost ms-shadow',
        },
    ];
    typeOptions: SelectItem[] = [
        {
            label: 'Artifact',
            value: 'artifact',
        },
        {
            label: 'Creature',
            value: 'creature',
        },
        {
            label: 'Enchantment',
            value: 'enchantment',
        },
        {
            label: 'Instant',
            value: 'instant',
        },
        {
            label: 'Land',
            value: 'land',
        },
        {
            label: 'Planeswalker',
            value: 'planeswalker',
        },
        {
            label: 'Sorcery',
            value: 'sorcery',
        },
    ];

    searchClicked(event: any) {
        this.onSearch.emit();
    }

    resetClicked(event: any) {
        this.onReset.emit();
    }

    textKeyUp(event: any) {
        if (event.which === 13) {
            this.onSearch.emit();
        }
    }
}
