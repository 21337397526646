import { Component, Input, Output, EventEmitter } from '@angular/core';
import { QueryParams } from '../model/QueryParams';
import { SelectItem } from 'primeng/api';

@Component({
    selector: 'app-input-slider',
    templateUrl: './input-slider.component.html',
    styleUrls: ['./input-slider.component.css']
  })
export class InputSliderComponent {
    
    @Input() min: number;
    @Input() max: number;

    @Input() range: number[];
    @Output() rangeChange = new EventEmitter<number[]>();

    @Input() label: string;

    public onSliderChange(event): void {
      this.rangeChange.emit(this.range);
    }
}
