<div class="sidebar-button">
    <p-button (click)="toolsService.showToolSidebar = true" icon="fa fa-th-large fa-large" 
        class="ui-button">
    </p-button>
</div>

<div class="maximize-button">
    <p-button (click)="toolsService.maximizeContent = ! toolsService.maximizeContent" 
        [icon]="toolsService.maximizeContent ? 'fa fa-angle-double-down fa-2' : 'fa fa-angle-double-up fa-2'" class="ui-button" 
        >
    </p-button>
</div>

<div *ngIf="toolsService.showCardBrowser" style="padding: 0; margin: 0;">
    <app-query-builder [queryParams]="queryParams" (onSearch)="onSearch()" (onReset)="onReset()"
        *ngIf="! toolsService.maximizeContent">
    </app-query-builder>

    <app-card-list #cardList [queryParams]="queryParams" >
    </app-card-list>
</div>

<p-sidebar [(visible)]="toolsService.showToolSidebar" position="right" [baseZIndex]="10000">
    <div style="padding: 1rem; text-align: center; margin-bottom: 2rem;">
        <i class="ms ms-dfc-ignite ms-6x"></i>
    </div>
    <div class="p-grid">
        <div class="p-col-12 wide-p-button">
            <p-button (click)="onShowDeckInputDialog()" label="Check Deck List" icon="fa fa-thumbs-up" 
                class="ui-button" [style]="{'width':'100%'}">
            </p-button>
        </div>
        <div class="p-col-12 wide-p-button">
            <p-button (click)="onShowDelaListDialog()" label="Season Changes" icon="fa fa-exchange" 
                class="ui-button" [style]="{'width':'100%'}">
            </p-button>
        </div>
        <div class="p-col-12 wide-p-button">
            <p-button (click)="onShowUncostedListDialog()" label="Unavailable Cards" icon="fa fa-ban" 
                class="ui-button" [style]="{'width':'100%'}">
            </p-button>
        </div>
        
        <div style="margin: 1rem; padding: 0rem; border-bottom: 1px solid grey; width: 100%;">
        </div>

        <div class="p-col-12 wide-p-button">
            <p-button (click)="onShowCardBrowser()" label="Card Search" icon="fa fa-search" 
                [disabled]="toolsService.showCardBrowser"
                class="ui-button" [style]="{'width':'100%'}">
            </p-button>
        </div>

        <div class="p-col-12 wide-p-button">
            <p-button (click)="onShowLeaderboard()" label="Season Leaderboard" icon="fa fa-trophy" 
                [disabled]="toolsService.showLeaderboard"
                class="ui-button" [style]="{'width':'100%'}">
            </p-button>
        </div>

        <div style="margin: 1rem; padding: 0rem; border-bottom: 1px solid grey; width: 100%;">
        </div>

        <div class="p-col-12 wide-p-button">
            <p-button (click)="onDeckCreateClick()" label="Create Deck" icon="fa fa-file-text-o" 
                class="ui-button" [style]="{'width':'100%'}" [disabled]="this.toolsService.showDeckEditor" >
            </p-button>
        </div>
        <div class="p-col-12 wide-p-button">
            <p-button (click)="onViewDeckClick()" label="View Deck" icon="fa fa-list" 
                [disabled]="!deckBuilderService.IsActiveDeck || this.toolsService.showDeckEditor"
                class="ui-button" [style]="{'width':'100%'}">
            </p-button>
        </div>

        <div class="p-col-12">
            <input type="file" id="deckUpload" accept='application/json' class="input-file" (change)="uploadDeck($event)">
            <label for="deckUpload">            
                <div class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-icon-left" style="width: 100%;">                
                    <span class="fa fa-upload ui-clickable ui-button-icon-left ng-star-inserted"></span>
                    <span class="ui-button-text ui-clickable" style="width: 100%; text-align: center;">Upload Deck</span>
                </div>
            </label>
        </div>
        <div class="p-col-12 wide-p-button">
            <p-button (click)="onSaveDeckClick()" label="Download Deck" icon="fa fa-download" 
                [disabled]="!deckBuilderService.IsActiveDeck"
                class="ui-button" [style]="{'width':'100%'}">
            </p-button>
        </div>
    </div>
</p-sidebar>

<app-deck-check>
</app-deck-check>

<!-- [(popupActive)]="toolsService.showUncostedListDialog" -->
<app-uncosted-list [(popupActive)]="toolsService.showUncostedListDialog">
</app-uncosted-list>

<app-delta3-list [(popupActive)]="toolsService.showDelta3List" ShowLegality="true">
</app-delta3-list>


<app-deck-editor *ngIf="toolsService.showDeckEditor">    
</app-deck-editor>

<app-card-details [card]="toolsService.activeCard">
</app-card-details>

<app-leaderboard *ngIf="toolsService.showLeaderboard">    
</app-leaderboard>