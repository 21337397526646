import { Component, Input } from '@angular/core';
import { DatabaseService } from '../database.service';
import { Observable } from 'rxjs';
import { DbResult } from '../model/DbResult';
import { Card } from '../model/Card';
import { QueryParams } from '../model/QueryParams';
import { LazyLoadEvent } from 'primeng/api';
import { ToolsService, Dialog } from './tools.service';

@Component({
  selector: 'app-deck-check',
  templateUrl: './deck-check.component.html',
  styleUrls: ['./deck-check.component.css']
})
export class DeckCheckComponent {
    deckList: Card[] = null;
    deckListText: string = '';
    isDeckValid: boolean = false;

    requestList: string[] = null;

    JSON = JSON;

    constructor(private databaseService: DatabaseService, public toolsService: ToolsService) {
    }



    onShowDeckList(event: any) {

    }

    onHideDeckList(event: any) {
      this.deckList = null;
    }


    onShowDeckInput(event: any) {
      this.deckListText = '';

    }

    onHideDeckInput(event: any) {
    }

    public onCheckDeck() {
      this.requestList = this.deckListText
        .split('\n').map(x => x.trim().replace(/^\d+\s*x\s+|^\d+\s+/, ''))
        .filter(x => x != null && x.length > 0 );
      this.toolsService.showDeckInputDialog = false;
      this.toolsService.activeDialog = Dialog.DeckValidator;
      this.databaseService.CheckDeckList( this.requestList ).subscribe( deckList => this.processDeckList(deckList) );
    }

    processDeckList( deckList: Card[] ): void {
      this.isDeckValid = deckList.every( c => c != null && c.Legal);
      this.deckList = deckList;
    }

}
