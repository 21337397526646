import { Component, Input } from '@angular/core';
import { DatabaseService } from '../database.service';
import { Card } from '../model/Card';
import { ToolsService } from './tools.service';
import { DbResult } from '../model/DbResult';
import { Utils } from '../model/Utils';
import { PopupCardList } from './popup-card-list.base';

@Component({
  selector: 'app-delta3-list',
  templateUrl: './popup-card-list.base.html',
  styleUrls: ['./popup-card-list.base.css']
})
export class SeasonDeltaComponent extends PopupCardList {

    constructor(databaseService: DatabaseService, toolsService: ToolsService) {
      super(databaseService, toolsService);
      this.headerTitle = 'Changed Cards';
      this.headerText = 'The cards in this list have been added or dropped from the previous season.';
    }

    public onShowList(event: any) {
      this.databaseService.GetSeason3Delta().subscribe( deckList => this.processDeckList(deckList) );
    }

}
