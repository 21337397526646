<div style="display: table;" [class]="card.Legal ? 'card-row' : 'card-row illegal-card'">

    <div style="display: table-cell; vertical-align: top;" class="clearfix">
        <img src="{{ card.ImageUrl }}" width="73" height="102" (click)="onCardFaceClick(card)"
            style="cursor: pointer;">
    </div>  
    <div style="display: table-cell;  vertical-align: top; width: 100%; padding-right: 0.5rem; padding-left: 0.5rem;" class="clearfix">
        <div>
            <strong>{{ card.Name }}</strong>
            <span style="float: right;"> 
                <span [innerHTML]="iconifyText(card.ManaCost)"> </span> 
                <br>
                <button pButton type="button" icon="fa fa-trash" class="ui-button-danger" style="margin-top: 0.5rem; margin-bottom: 0.5rem; float: right;"
                    [disabled]="deckEntry.mainCount > 0 || deckEntry.sideCount > 0" (click)="onRemoveFromDeckClick()"
                    *ngIf="deckEntry">
                </button>
                <br>
                <span style="float: right;">${{ card.Price.toFixed(2) }}</span>
            </span> 
            <!-- ui-button-raised -->

        </div>
        <span><em>{{ card.SubType ? card.Type + " - " + card.SubType : card.Type }}</em></span><br>
        <span [innerHTML]="iconifyText(card.OracleText)"></span>
        <div *ngIf="card.Power != null || card.Toughness != null"> 
            <strong>{{ card.Power }} / {{ card.Toughness }} </strong>                         
        </div>
    </div>
    <div style="display: table-cell; vertical-align: top; min-width: 6rem;" class="clearfix" *ngIf="deckEntry">
        <div>
            <span style="display: table-row; white-space: nowrap;">Main Deck: {{ deckEntry.mainCount }}</span>            
            <p-button icon="fa fa-minus" class="ui-button-raised" [style]="{'margin-right':'0.5rem'}" 
                [disabled]="deckEntry.mainCount === 0" (click)="onMainBoardRemoveClick()">
            </p-button>
            <p-button icon="fa fa-plus" class="ui-button-raised" [style]="{'margin-left':'0.5rem'}"
                [disabled]="card.Type !== 'Basic Land' && deckEntry.mainCount >= 4" (click)="onMainBoardAddClick()">
            </p-button>
        </div>
        <div>
            <span style="display: table-row; white-space: nowrap;">Side Board: {{ deckEntry.sideCount}}</span>
            <p-button icon="fa fa-minus" class="ui-button-raised" [style]="{'margin-right':'0.5rem'}" 
                [disabled]="deckEntry.sideCount === 0" (click)="onSideBoardRemoveClick()">
            </p-button>
            <p-button icon="fa fa-plus" class="ui-button-raised" [style]="{'margin-left':'0.5rem'}"
                [disabled]="card.Type !== 'Basic Land' && deckEntry.sideCount >= 4" (click)="onSideBoardAddClick()">
            </p-button>
        </div>
    </div>

</div>
