import { Component } from '@angular/core';
import { DatabaseService } from '../database.service';
import { DbResult } from '../model/DbResult';
import { Card } from '../model/Card';
import { MessageService, Message } from 'primeng/api';
import { ToolsService } from './tools.service';
import { DeckBuilderService } from './deck-builder.service';
import { stringify } from 'querystring';
import { Utils, IDictionary } from '../model/Utils';
import { DeckEntry, DeckCard, Deck } from '../model/Deck';
import { throwError } from 'rxjs';


@Component({
  selector: 'app-deck-editor',
  templateUrl: './deck-editor.component.html',
  styleUrls: ['./deck-editor.component.css']
})
export class DeckEditorComponent {


    cards: Card[] = null;
    cmcData = null;
    colorData = null;
    deckText: string = null;

    constructor(
        public databaseService: DatabaseService,
        public toolsService: ToolsService,
        public messageService: MessageService,
        public deckBuilderService: DeckBuilderService) {
            let lookup = null;
            if (this.deckBuilderService.ActiveDeck.version == null || this.deckBuilderService.ActiveDeck.version < 2) {
                lookup = this.databaseService.CardIdLookup( this.deckBuilderService.ActiveDeck.cards.map( de => de.id ) );
            } else {
                lookup = this.databaseService.CardOracleIdLookup( this.deckBuilderService.ActiveDeck.cards.map( de => de.id ) );
            }

            lookup.subscribe( payload => {
                this.deckBuilderService.ConvertDeck( this.deckBuilderService.ActiveDeck, payload );
                this.cards = payload;
                this.refreshData();
            } );
    }

    public getCardForId( id: string ): Card {
        return this.cards.filter( c => c.OracleId === id )[0];
    }

    public mainDeckCount(): number {
        return this.sum(
            this.deckBuilderService.ActiveDeck.cards.map( de => de.mainCount )
        );
    }

    public sideBoardCount(): number {
        return this.sum(
            this.deckBuilderService.ActiveDeck.cards.map( de => de.sideCount )
        );
    }

    public sideBoardList(): string[] {
        return this.cards
            .sort( (a, b) => a.Cmc - b.Cmc )
            .map( c => {
                const count = this.deckBuilderService.ActiveDeck.cards.find( de => de.id === c.OracleId ).sideCount;
                if (count > 0) {
                    return  `${count} x ${c.Name} ${Utils.IconifyText(c.ManaCost)}`;
                } else {
                    return null;
                }
            }).filter( val => val != null );
    }

    public landCount(): number {
        const items = this.cards
            .filter( c => c.Type.includes('Land') )
            .map( c => this.deckBuilderService.ActiveDeck.cards.find( de => de.id === c.OracleId ).mainCount );

        return this.sum( items );
    }

    public landList(): string[] {
        return this.cards
            .filter( c => c.Type.includes('Land') )
            .map( c => {
                const count = this.deckBuilderService.ActiveDeck.cards.find( de => de.id === c.OracleId ).mainCount;
                if (count > 0) {
                    return  `${count} x ${c.Name}`;
                } else {
                    return null;
                }
            }).filter( val => val != null );
    }

    public creatureCount(): number {
        const items = this.cards
            .filter( c => c.Type.includes('Creature') )
            .map( c => this.deckBuilderService.ActiveDeck.cards.find( de => de.id === c.OracleId ).mainCount );

        return this.sum( items );
    }

    public creatureList(): string[] {
        return this.cards
            .filter( c => c.Type.includes('Creature') )
            .sort( (a, b) => a.Cmc - b.Cmc )
            .map( c => {
                const count = this.deckBuilderService.ActiveDeck.cards.find( de => de.id === c.OracleId ).mainCount;
                if (count > 0) {
                    return  `${count} x ${c.Name} ${Utils.IconifyText(c.ManaCost)}`;
                } else {
                    return null;
                }
            }).filter( val => val != null );
    }

    public spellCount(): number {
        const items =  this.cards
            .filter( c => !c.Type.includes('Creature') &&  !c.Type.includes('Land'))
            .map( c => this.deckBuilderService.ActiveDeck.cards.find( de => de.id === c.OracleId ).mainCount );

        return this.sum( items );
    }

    public spellList(): string[] {
        return this.cards
            .filter( c => !c.Type.includes('Creature') && !c.Type.includes('Land'))
            .sort( (a, b) => a.Cmc - b.Cmc )
            .map( c => {
                const count = this.deckBuilderService.ActiveDeck.cards.find( de => de.id === c.OracleId ).mainCount;
                if (count > 0) {
                    return  `${count} x ${c.Name} ${Utils.IconifyText(c.ManaCost)}`;
                } else {
                    return null;
                }
            }).filter( val => val != null );
    }

    public isDeckValid(): boolean {
        return this.deckBuilderService.ActiveDeck.cards
            .filter( de => de.mainCount > 0 || de.sideCount > 0 )
            .every( de => this.cards.find( c => c.OracleId === de.id ).Legal );
    }

    public deckChanged() {
        this.deckBuilderService.WriteToCookie();
        this.refreshData();
    }

    public refreshData() {
        this.buildCmcGraphData();
        this.buildColorGraphData();
        this.deckText = this.buildDeckText();
    }

    public buildCmcGraphData(): void {
        const cardLookup = Utils.BuildDictionary( this.cards, card => card.OracleId );
        const deckEntries = this.deckBuilderService.ActiveDeck.cards
            .filter( de => de.mainCount > 0 );

        this.cmcData = {
            labels: ['0', '1', '2', '3', '4', '5', '6', '7+'],
            datasets: [
                {
                    label: 'Main Deck Mana Costs',
                    backgroundColor: '#42A5F5',
                    borderColor: '#1E88E5',
                    data: [
                        this.getFilteredCardCount( cardLookup, deckEntries, c => c.Cmc === 0 ),
                        this.getFilteredCardCount( cardLookup, deckEntries, c => c.Cmc === 1 ),
                        this.getFilteredCardCount( cardLookup, deckEntries, c => c.Cmc === 2 ),
                        this.getFilteredCardCount( cardLookup, deckEntries, c => c.Cmc === 3 ),
                        this.getFilteredCardCount( cardLookup, deckEntries, c => c.Cmc === 4 ),
                        this.getFilteredCardCount( cardLookup, deckEntries, c => c.Cmc === 5 ),
                        this.getFilteredCardCount( cardLookup, deckEntries, c => c.Cmc === 6 ),
                        this.getFilteredCardCount( cardLookup, deckEntries, c => c.Cmc > 6 )
                    ]
                },
            ]
        };
    }

    public buildColorGraphData(): void {
        const cardLookup = Utils.BuildDictionary( this.cards, card => card.OracleId );
        const deckEntries = this.deckBuilderService.ActiveDeck.cards
            .filter( de => de.mainCount > 0 );

        this.colorData = {
            labels: ['White', 'Blue', 'Black', 'Red', 'Green'],
            datasets: [
                {
                    label: 'Color Spread By Card Count',
                    backgroundColor: 'rgba(179,181,198,0.2)',
                    borderColor: 'rgba(179,181,198,1)',
                    pointBackgroundColor: 'rgba(179,181,198,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(179,181,198,1)',
                    data: [
                        this.getFilteredCardCount( cardLookup, deckEntries, c => c.Colors.includes('W') ),
                        this.getFilteredCardCount( cardLookup, deckEntries, c => c.Colors.includes('U') ),
                        this.getFilteredCardCount( cardLookup, deckEntries, c => c.Colors.includes('B') ),
                        this.getFilteredCardCount( cardLookup, deckEntries, c => c.Colors.includes('R') ),
                        this.getFilteredCardCount( cardLookup, deckEntries, c => c.Colors.includes('G') )
                    ]
                }
            ]
        };
    }

    private getFilteredCardCount( cardLookup: IDictionary<Card>, deckEntries: DeckEntry[], cmcCheck: (card: Card) => boolean ): number {
        const items = deckEntries
            .filter( de => !cardLookup.get(de.id).Type.includes('Land') )
            .filter( de => cmcCheck( cardLookup.get(de.id) ) )
            .map( de => de.mainCount );

        return this.sum( items );
    }

    private sum(array: number[]): number {
        if ( array.length === 0 ) {
            return 0;
        }
        return array.reduce( (sum, current) => sum + current);
    }

    private buildDeckText(): string {
        const cardLookup = Utils.BuildDictionary( this.cards, card => card.OracleId );

        const mainBoard: DeckCard[] = this.deckBuilderService.ActiveDeck.cards
            .filter( de => de.mainCount > 0 )
            .map( de => Object.assign( {}, de, cardLookup.get(de.id) ) as DeckCard );

        const sideBoard: DeckCard[] = this.deckBuilderService.ActiveDeck.cards
            .filter( de => de.sideCount > 0 )
            .map( de => Object.assign( {}, de, cardLookup.get(de.id) ) as DeckCard );


        const deck: DeckCard[] = mainBoard
            .filter( card => card.Type.includes('Creature') )
            .sort( (a, b) => a.Cmc - b.Cmc );

        deck.push(
            ...mainBoard
                .filter( card => !card.Type.includes('Creature') && !card.Type.includes('Land'))
                .sort( (a, b) => a.Cmc - b.Cmc ) );

        deck.push(
            ...mainBoard
                .filter( card => card.Type.includes('Land'))
                .sort( (a, b) => a.Name.localeCompare(b.Name) ) );

        let retval = '';

        deck.forEach( card => retval += `${card.mainCount} ${card.Name}\n` );
        retval += '\n';
        sideBoard.forEach( card => retval += `${card.sideCount} ${card.Name}\n` );

        return retval;
    }

    public nothing() {
        const message: Message = {};
        message.severity = 'info';
        message.summary = 'Unimplmented';
        message.detail = 'This button not yet implemented.';
        this.messageService.add(message);
      }

      public trackByDeckEntry(item: DeckEntry, index) {
          if (item == null) {
            return null;
          }
          return item.id;
      }
}
