import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Deck, DeckEntry} from '../model/Deck';
import { Card } from '../model/Card';
import * as FileSaver from 'file-saver';
import { Utils } from '../model/Utils';
import { MessageService } from 'primeng/api';

@Injectable()
export class DeckBuilderService {

    private _activeDeck: Deck = null;
    get ActiveDeck(): Deck {
        return this._activeDeck;
    }

    get IsActiveDeck(): boolean {
        return this.ActiveDeck != null;
    }

    constructor( 
        protected cookieService: CookieService,
        protected messageService: MessageService ) {
    }

    public CardIsInDeck(card: Card) {
        if ( this.ActiveDeck != null && card != null) {
            return this.ActiveDeck.cards.findIndex( de => de.id === card.OracleId ) > -1;
        }
        return false;
    }

    public AddCardToDeck(card: Card) {
        if ( this.ActiveDeck != null && !this.CardIsInDeck(card) ) {
            // tslint:disable-next-line: no-angle-bracket-type-assertion
            this.ActiveDeck.cards.push( <DeckEntry> {
                id: card.OracleId,
                mainCount: 0,
                sideCount: 0
            });
        }
    }

    public TryRemoveCard(card: Card) {
        if ( this.ActiveDeck != null) {
            const deckEntry = this.ActiveDeck.cards.find( de => de.id === card.OracleId );
            return this.TryRemoveDeckEntry(deckEntry);
        }
        return true;
    }

    public TryRemoveDeckEntry(deckEntry: DeckEntry) {
        if ( this.ActiveDeck != null) {
            if ( deckEntry != null ) {
                if (deckEntry.mainCount === 0 && deckEntry.sideCount === 0) {
                    this.ActiveDeck.cards = this.ActiveDeck.cards.filter( de => de !== deckEntry );
                    return true;
                } else {
                    return false;
                }
            }
        }
        return true;
    }

    public CreateNewDeck(deckName: string) {
        // tslint:disable-next-line: no-angle-bracket-type-assertion
        const deck: Deck = <Deck> {
            name: deckName,
            cards: [],
            version: 2,
        };
        this._activeDeck = deck;
    }

    public WriteToCookie() {
        // const serialized = JSON.stringify( this.ActiveDeck, ['name', 'cards'] );
        const serialized = JSON.stringify( this.ActiveDeck );
        this.cookieService.set('activeDeck', serialized, 120, null, null, true );
    }

    public ReadFromCookie() {
        let deck: Deck = null;
        if ( this.cookieService.check('activeDeck') ) {
            const serialized = this.cookieService.get('activeDeck');
            deck = JSON.parse(serialized) as Deck;
            if (deck.version == null) {
                deck.version = 1;
            }
        }

        this._activeDeck = deck;
    }

    public ReadFromString(serialized: string): string {
        try {
            this._activeDeck = JSON.parse(serialized) as Deck;
        } catch (error) {
            return error.toString();
        }

        return null;
    }

    public SaveToFile() {
        const serialized = JSON.stringify( this.ActiveDeck );

        const blob = new Blob([serialized], {
            type: 'application/json'
        });
        FileSaver.saveAs(blob, 'deck.json');
    }

    public ConvertDeck(deck: Deck, cards: Card[]) {
        if (deck.version != null && deck.version < 2) {
            const cardLookup = Utils.BuildDictionary( cards, card => card.Id );
            deck.cards.forEach( de => de.id = cardLookup.get(de.id).OracleId );
            deck.version = 2;
        }
    }

    public onDeckListClick( card: Card ) {
        if ( this.IsActiveDeck ) {
          if (this.CardIsInDeck(card)) {
            if (this.TryRemoveCard(card)) {
              this.messageService.add( {severity: 'success', summary: 'Card removed from decklist', detail: card.Name} );
              this.WriteToCookie();
            } else {
              this.messageService.add( {
                severity: 'error',
                summary: 'Card cannot removed from decklist',
                detail: card.Name + ' is either in the main deck or sideboard.'} );
            }
          } else {
            this.AddCardToDeck(card);
            this.messageService.add( {severity: 'success', summary: 'Card added to decklist', detail: card.Name} );
            this.WriteToCookie();
          }
        }
      }
}
