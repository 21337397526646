
<p-dialog [(visible)]="popupActive" [draggable]="true" [modal]="true" [dismissableMask]="true" 
    [closable]="true" [responsive]="false" [header]="headerTitle" [blockScroll]="true" 
    positionTop="100" (onShow)="onShowList($event)" (onHide)="onHideList($event)">


    <div style="max-width: 30rem;">

        <p class="header-row">
            {{ headerText }}
        </p>
        <div style="text-align: center;" *ngIf="!deckList">
            <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
            <span class="sr-only">Loading...</span>
        </div>

        <div *ngIf="deckList" style="display: table;" style="overflow-y: scroll; max-width: 30rem; max-height: 75vh;">
            <div *ngFor="let card of deckList" class="card-row"> 
                <div *ngIf="card" [class]="CalcCardClass(card)" style="display: table-row;"> 
                    
                    <img style="display: table-cell; vertical-align: top; padding: 0.5rem; cursor: pointer;" 
                        (click)="onCardFaceClick(card)" src="{{ card.ImageUrl }}" width="73" height="102">
                    
                    <div style="display: table-cell; vertical-align: top; width: auto;" class="clearfix">
                        <div>
                            <strong>{{ card.Name }}</strong>
                            <span style="float: right;" [innerHTML]="iconifyText(card.ManaCost)"></span> 
                        </div>
                        <span [innerHTML]="iconifyText(card.OracleText)"></span>
                        <div *ngIf="card.Power != null || card.Toughness != null"> 
                            <strong>{{ card.Power }} / {{ card.Toughness }} </strong>                         
                        </div>
                    </div>                

                </div>       
            </div>
        </div>
    </div>

</p-dialog>
