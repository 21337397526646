import { HttpParams } from '@angular/common/http';

export class QueryParams {
    public Colors: string[] = [];
    public SearchString: string = '';
    public TextSearch: string = '';
    public Sort: string = 'alpha';
    public Type: string = '';
    public SubType: string = '';
    public ExcludeUnselectedColors: boolean = false;
    public RequireAllColors: boolean = false;
    public LegalOnly: boolean = false;
    public CmcRange: number[] = [0, 16];
    public PowerRange: number[] = [-1, 16];
    public ToughnessRange: number[] = [-1, 16];

    public SetHttpParams( params: HttpParams ) {
        return params
            .append( 'legal', this.LegalOnly ? '1' : '0' )
            .append( 'colors', this.Colors.join() )
            .append( 'search', this.SearchString.trim() )
            .append( 'sort', this.Sort )
            .append( 'type', this.Type.trim() )
            .append( 'subType', this.SubType.trim() )
            .append( 'excludeUnselectedColors', this.ExcludeUnselectedColors ? '1' : '0' )
            .append( 'requireAllColors', this.RequireAllColors ? '1' : '0' )
            .append( 'cmcMin', this.CmcRange[0].toString() )
            .append( 'cmcMax', this.CmcRange[1].toString() )
            .append( 'powerMin', this.PowerRange[0].toString() )
            .append( 'powerMax', this.PowerRange[1].toString() )
            .append( 'toughnessMin', this.ToughnessRange[0].toString() )
            .append( 'toughnessMax', this.ToughnessRange[1].toString() )
            .append( 'oracleText', this.TextSearch );
    }
}
