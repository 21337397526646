<h1>Legal Combos</h1>

<div style="text-align: center;" *ngIf="!ComboData">
    <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
    <span class="sr-only">Loading...</span>
</div>

<div *ngIf="ComboData">
    <app-card-combo *ngFor="let combo of ComboData.Combos" 
        [Combo]="combo" [Cards]="ComboData.Cards">
    </app-card-combo>
</div>