import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { BrowserComponent } from './browser/browser.component';
import { QueryBuilderComponent } from './browser/queryBuilder.component';
import { CardListComponent } from './browser/card-list.component';
import { DatabaseService } from './database.service';
import { DeckCheckComponent } from './browser/deck-check.component';
import { InputSliderComponent } from './browser/input-slider.component';
import { ToolsService } from './browser/tools.service';
import { UncostedListComponent } from './browser/uncosted-list.component';
import { DeckBuilderService } from './browser/deck-builder.service';
import { DeckEditorComponent } from './browser/deck-editor.component';
import { DeckEditorEntryComponent } from './browser/deck-editor-entry.component';
import { CardDetailsComponent } from './browser/card-details.component';
import { LeaderboardComponent } from './browser/leaderboard.component';
import { LeaderboardCellComponent } from './browser/leaderboard-cell.component';

import { DataViewModule } from 'primeng/dataview';
import { PaginatorModule } from 'primeng/paginator';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule} from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { InputTextModule } from 'primeng/inputtext';
import { SliderModule } from 'primeng/slider';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SidebarModule } from 'primeng/sidebar';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ChartModule } from 'primeng/chart';

import { CookieService } from 'ngx-cookie-service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SeasonDeltaComponent } from './browser/delta3-list.component';
import { LegalCombosComponent } from './browser/legal-combos.component';
import { CardComboComponent } from './browser/card-combo.component';



@NgModule({
  declarations: [
    AppComponent,
    BrowserComponent,
    QueryBuilderComponent,
    CardListComponent,
    InputSliderComponent,
    DeckCheckComponent,
    UncostedListComponent,
    DeckEditorComponent,
    DeckEditorEntryComponent,
    CardDetailsComponent,
    LeaderboardComponent,
    LeaderboardCellComponent,
    SeasonDeltaComponent,
    LegalCombosComponent,
    CardComboComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,

    InfiniteScrollModule,

    DataViewModule,
    PaginatorModule,
    CardModule,
    PanelModule,
    CheckboxModule,
    ButtonModule,
    DropdownModule,
    VirtualScrollerModule,
    InputTextModule,
    SliderModule,
    ToggleButtonModule,
    SidebarModule,
    DialogModule,
    InputTextareaModule,
    MessageModule,
    ToastModule,
    ChartModule,
  ],
  providers: [DatabaseService, ToolsService, DeckBuilderService, CookieService, MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
