import { DatabaseService } from '../database.service';
import { Card } from '../model/Card';
import { ToolsService } from './tools.service';
import { DbResult } from '../model/DbResult';
import { Utils } from '../model/Utils';
import { EventEmitter, Input, Output, Directive } from '@angular/core';

@Directive()
export abstract class PopupCardList {
    deckList: Card[] = null;
    public headerText: string = '';
    public headerTitle: string = '';


    private _popupActive: boolean = false;
    @Output() popupActiveChange = new EventEmitter<boolean>();

    @Input()
    public get popupActive(): boolean {
      return this._popupActive;
    }
    public set popupActive(value: boolean) {
      this._popupActive = value;
      this.popupActiveChange.emit(value);
    }

    @Input() ShowLegality: boolean = false;

    constructor(protected databaseService: DatabaseService, public toolsService: ToolsService) {
    }

    public abstract onShowList(event: any)

    onHideList(event: any) {
      this.deckList = null;
    }

    public processDeckList( payload: DbResult<Card> ) {
      this.deckList = payload.Records;
    }

    iconifyText(text: string): string {
      return Utils.IconifyText(text);
    }

    onCardFaceClick(card: Card) {
      this.toolsService.activeCard = card;
      this.toolsService.showCardDetails = true;
    }

    CalcCardClass(card: Card) {
      let retval: string = 'clearfix';

      if (this.ShowLegality && card.Legal === false) {
        retval += ' illegal-card';
      }
      return retval;
    }
}
