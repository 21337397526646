export class Utils {

    public static IconifyText(text: string): string {
        if ( text != null ) {
            text = text.replace( /\:mana(\d+)\:/, '{$1}')

            text = text.split('{T}').join('<i class="ms ms-tap ms-cost ms-shadow"></i>');
            text = text.split('{t}').join('<i class="ms ms-tap ms-cost ms-shadow"></i>');
            text = text.split('{W}').join('<i class="ms ms-w ms-cost ms-shadow"></i>');
            text = text.split('{w}').join('<i class="ms ms-w ms-cost ms-shadow"></i>');
            text = text.split('{U}').join('<i class="ms ms-u ms-cost ms-shadow"></i>');
            text = text.split('{u}').join('<i class="ms ms-u ms-cost ms-shadow"></i>');
            text = text.split('{B}').join('<i class="ms ms-b ms-cost ms-shadow"></i>');
            text = text.split('{b}').join('<i class="ms ms-b ms-cost ms-shadow"></i>');
            text = text.split('{R}').join('<i class="ms ms-r ms-cost ms-shadow"></i>');
            text = text.split('{r}').join('<i class="ms ms-r ms-cost ms-shadow"></i>');
            text = text.split('{G}').join('<i class="ms ms-g ms-cost ms-shadow"></i>');
            text = text.split('{g}').join('<i class="ms ms-g ms-cost ms-shadow"></i>');
            text = text.split('{X}').join('<i class="ms ms-x ms-cost ms-shadow"></i>');
            text = text.split('{x}').join('<i class="ms ms-x ms-cost ms-shadow"></i>');
            text = text.split('{C}').join('<i class="ms ms-c ms-cost ms-shadow"></i>');
            text = text.split('{c}').join('<i class="ms ms-c ms-cost ms-shadow"></i>');

            text = text.split('{W/P}').join('<i class="ms ms-wp ms-cost ms-shadow"></i>');
            text = text.split('{U/P}').join('<i class="ms ms-up ms-cost ms-shadow"></i>');
            text = text.split('{B/P}').join('<i class="ms ms-bp ms-cost ms-shadow"></i>');
            text = text.split('{R/P}').join('<i class="ms ms-rp ms-cost ms-shadow"></i>');
            text = text.split('{G/P}').join('<i class="ms ms-gp ms-cost ms-shadow"></i>');

            text = text.split('{0}').join('<i class="ms ms-0 ms-cost ms-shadow"></i>');
            text = text.split('{1}').join('<i class="ms ms-1 ms-cost ms-shadow"></i>');
            text = text.split('{2}').join('<i class="ms ms-2 ms-cost ms-shadow"></i>');
            text = text.split('{3}').join('<i class="ms ms-3 ms-cost ms-shadow"></i>');
            text = text.split('{4}').join('<i class="ms ms-4 ms-cost ms-shadow"></i>');
            text = text.split('{5}').join('<i class="ms ms-5 ms-cost ms-shadow"></i>');
            text = text.split('{6}').join('<i class="ms ms-6 ms-cost ms-shadow"></i>');
            text = text.split('{7}').join('<i class="ms ms-7 ms-cost ms-shadow"></i>');
            text = text.split('{8}').join('<i class="ms ms-8 ms-cost ms-shadow"></i>');
            text = text.split('{9}').join('<i class="ms ms-9 ms-cost ms-shadow"></i>');
            text = text.split('{10}').join('<i class="ms ms-10 ms-cost ms-shadow"></i>');
            text = text.split('{11}').join('<i class="ms ms-11 ms-cost ms-shadow"></i>');
            text = text.split('{12}').join('<i class="ms ms-12 ms-cost ms-shadow"></i>');
            text = text.split('{13}').join('<i class="ms ms-13 ms-cost ms-shadow"></i>');
            text = text.split('{14}').join('<i class="ms ms-14 ms-cost ms-shadow"></i>');
            text = text.split('{15}').join('<i class="ms ms-15 ms-cost ms-shadow"></i>');
            text = text.split('{16}').join('<i class="ms ms-16 ms-cost ms-shadow"></i>');
            text = text.split('{17}').join('<i class="ms ms-17 ms-cost ms-shadow"></i>');
            text = text.split('{18}').join('<i class="ms ms-18 ms-cost ms-shadow"></i>');
            text = text.split('{19}').join('<i class="ms ms-19 ms-cost ms-shadow"></i>');
            text = text.split('{20}').join('<i class="ms ms-20 ms-cost ms-shadow"></i>');

            text = text.split('{W/U}').join('<i class="ms ms-wu ms-cost ms-shadow"></i>');
            text = text.split('{U/B}').join('<i class="ms ms-ub ms-cost ms-shadow"></i>');
            text = text.split('{U/R}').join('<i class="ms ms-ur ms-cost ms-shadow"></i>');
            text = text.split('{B/R}').join('<i class="ms ms-br ms-cost ms-shadow"></i>');
            text = text.split('{B/G}').join('<i class="ms ms-bg ms-cost ms-shadow"></i>');

            text = text.split('{R/W}').join('<i class="ms ms-rw ms-cost ms-shadow"></i>');
            text = text.split('{R/G}').join('<i class="ms ms-rg ms-cost ms-shadow"></i>');
            text = text.split('{G/W}').join('<i class="ms ms-gw ms-cost ms-shadow"></i>');
            text = text.split('{G/U}').join('<i class="ms ms-gu ms-cost ms-shadow"></i>');
            text = text.split('{W/B}').join('<i class="ms ms-wb ms-cost ms-shadow"></i>');

            text = text.split('{2/W}').join('<i class="ms ms-2w ms-cost ms-shadow"></i>');
            text = text.split('{2/U}').join('<i class="ms ms-2u ms-cost ms-shadow"></i>');
            text = text.split('{2/B}').join('<i class="ms ms-2b ms-cost ms-shadow"></i>');
            text = text.split('{2/R}').join('<i class="ms ms-2r ms-cost ms-shadow"></i>');
            text = text.split('{2/G}').join('<i class="ms ms-2g ms-cost ms-shadow"></i>');

            text = text.split('{E}').join('<i class="ms ms-e ms-cost ms-shadow"></i>');
            text = text.split('{S}').join('<i class="ms ms-s ms-cost ms-shadow"></i>');
            text = text.split('{P}').join('<i class="ms ms-p ms-cost ms-shadow"></i>');

            return text;
        }
        return '';
    }

    public static BuildDictionary<T>( list: T[], keySelector: (item: T) => string ): IDictionary<T> {
        const retval = {};

        list.forEach(element => {
            retval[ keySelector(element) ] = element;
        });

        return new Dictionary<T>(retval);
    }
}

export class Dictionary<V> implements IDictionary<V> {

    constructor(private rawObject: any) {
    }

    get(key: string): V {
        // tslint:disable-next-line: no-angle-bracket-type-assertion
        return <V> this.rawObject[key];
    }

    keys(): string[] {
        return Object.getOwnPropertyNames(this.rawObject);
    }

    values(): V[] {
        return this.keys().map( k => this.get(k) );
    }
}

export interface IDictionary<V> {
    get(key: string): V;
    keys(): string[];
    values(): V[];
}
