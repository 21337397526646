<p-panel [header]="getComboTitle(Combo)" style="margin: 1rem;">
    <div class="p-grid">
        <div *ngFor="let oracleId of Combo.Cards" class="p-col">
            <img [src]="getCardImage(oracleId)" (click)="onCardFaceClick(oracleId)" width="146" height="204"
                    style="padding: 5px; cursor: pointer; ">
        </div>
        <div class="p-col-12">
            <span style="white-space: pre-wrap;" [innerHTML]="GetDescription(Combo)"></span>
        </div>
    </div>
</p-panel>