import { Component, Input } from '@angular/core';
import { DatabaseService } from '../database.service';
import { Card } from '../model/Card';
import { ToolsService } from './tools.service';
import { Utils } from '../model/Utils';
import { ComboData } from '../model/ComboData';
import { Combo } from '../model/Combo';

@Component({
  selector: 'app-legal-combos',
  templateUrl: './legal-combos.component.html',
  styleUrls: ['./legal-combos.component.css']
})
export class LegalCombosComponent  {

    public ComboData: ComboData;
    public data: string;

    constructor(protected databaseService: DatabaseService, protected toolsService: ToolsService) {
        this.databaseService.GetLegalCombos().subscribe( data => {
            this.ComboData = data;
            this.data = JSON.stringify(data);
        });
    }


}
