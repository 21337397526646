<div style="width: 100%; margin-top: 3rem;">
    
    <div style="text-align: center;" *ngIf="!matches || matches.length === 0">
        <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
        <span class="sr-only">Loading...</span>
    </div>

    <table style="margin-left:auto; margin-right:auto;" *ngIf="matches && matches.length">
        <tr>
            <td></td>
            <td *ngFor="let rowHeader of players" >
                <p class="vertical-text">{{ rowHeader }}</p>
            </td>
        </tr>
        <tr *ngFor="let rowPlayer of players; let rowIndex = index">
            <td>
                {{ rowPlayer }} <br/> {{ record(rowPlayer) }}
            </td>
            <td *ngFor="let colPlayer of players; let colIndex = index">
                <app-leaderboard-cell [matchResult]="getMatchResult(rowPlayer, colPlayer)" *ngIf="colPlayer !== rowPlayer">                    
                </app-leaderboard-cell>
            </td>
        </tr>
    </table>
</div>