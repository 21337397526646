export interface IMatchResult {
    player1: string;
    player2: string;
    gameResults: Outcome[];
}

export interface SeasonResult {
    players: string[];
    matchResults: IMatchResult[];
}

export class MatchResult implements IMatchResult {
    public player1: string;
    public player2: string;
    public gameResults: Outcome[];

    constructor(player1Name: string, player2Name: string, ...games: Outcome[]) {
        this.player1 = player1Name;
        this.player2 = player2Name;
        this.gameResults = games;
    }

    invertPlayers(): MatchResult {
        const games = this.gameResults.map( gr => {
        switch (gr) {
            case Outcome.Player1Win:
            return Outcome.Player2Win;
            case Outcome.Player2Win:
            return Outcome.Player1Win;
            case Outcome.Draw:
            return Outcome.Draw;
        }
        } );

        return new MatchResult( this.player2, this.player2, ...games);
    }

    matchResult(): Outcome {
        let result = 0;

        this.gameResults.forEach( r => result += r );

        if ( result === 0 ) {
            return Outcome.Draw;
        } else if ( result > 0 ) {
            return Outcome.Player1Win;
        }
        return Outcome.Player2Win;
    }
}

export enum Outcome {
    Player1Win = 1,
    Player2Win = -1,
    Draw = 0
}
