<div *ngIf="cards; else loading" class="table-header">
    <div *ngIf="cards.length > 0">
        <div class="p-grid stats" *ngIf="!toolsService.maximizeContent">
            <div class="p-col-12 ui-fluid" style="text-align: center;">
                <strong>Main Deck: {{ mainDeckCount() }}</strong>
                <p-message [severity]="isDeckValid() ? 'success' : 'error'" [style]="{'width':'100%', 'text-align': 'center'}"
                    [text]="isDeckValid() ? 'Deck appears to be legal' : 'Deck contains invalid cards'">
                </p-message>
            </div>

            <div class="p-col-12 stats"></div>

            <div class="p-col-12 p-md-6 p-lg-3">
                <strong>Creatures: {{ creatureCount() }}</strong>
                <div *ngFor="let card of creatureList()">
                    <div *ngIf="card" [innerHTML]="card"></div>
                </div>
            </div>
            <div class="p-col-12 p-md-6 p-lg-3">
                <strong>Spells: {{ spellCount() }}</strong>
                <div *ngFor="let card of spellList()" [innerHTML]="card">
                </div>
            </div>
            <div class="p-col-12 p-md-6 p-lg-3">
                <strong>Lands: {{ landCount() }}</strong>
                <div *ngFor="let card of landList()" [innerHTML]="card">
                </div>
            </div>
            <div class="p-col-12 p-md-6 p-lg-3">
                <strong>Side Board: {{ sideBoardCount() }}</strong>
                <div *ngFor="let card of sideBoardList()" [innerHTML]="card">
                </div>
            </div>

            <div class="p-col-12 p-md-6" *ngIf="cmcData">
                <p-chart type="bar" [data]="cmcData"></p-chart>
            </div>
            <div class="p-col-12 p-md-6" *ngIf="colorData">
                <p-chart type="radar" [data]="colorData"></p-chart>
            </div>

            <div class="p-col-12 p-md-6 wide-p-button">
                <p-button (click)="nothing()" label="Create Deck from TXT" icon="fa fa-file-o" 
                    class="ui-button" [style]="{'width':'100%'}">
                </p-button>
            </div>

            <div class="p-col-12 p-md-6 wide-p-button">
                <p-button (click)="toolsService.showDeckTextDialog = true" label="Show Deck as TXT" icon="fa fa-file-text-o" 
                    class="ui-button" [style]="{'width':'100%'}">
                </p-button>
            </div>
            
        </div>

        <app-deck-editor-entry
            *ngFor="let deckEntry of deckBuilderService.ActiveDeck.cards; trackBy: trackByDeckEntry"
            [card]="getCardForId(deckEntry.id)" [deckEntry]="deckEntry" (deckChanged)="deckChanged()">
        </app-deck-editor-entry>
    </div>


    
    <div *ngIf="cards.length === 0" style="text-align: center;width: 100%;">
        <h2>Deck is empty.</h2>
    </div>
</div>


<ng-template #loading>
    <div style="text-align: center; width: 100%">
        <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
        <span class="sr-only">Loading...</span>
    </div>
</ng-template>

<p-dialog [(visible)]="toolsService.showDeckTextDialog" [draggable]="false" [modal]="true" [dismissableMask]="true" 
    [closable]="true" header="Deck List Validator" [blockScroll]="true">

    <div style="text-align: center;">
        <textarea [rows]="25" [cols]="50" pInputTextarea [autoResize]="false"
            [(ngModel)]="deckText">
        </textarea>
    </div>

</p-dialog>
