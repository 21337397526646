<div> Found {{ totalRecords }} result{{ totalRecords === 1 ? '' : 's' }}. </div>

<div class="row" infiniteScroll style="height: 660px; overflow-y: scroll;"
    [infiniteScrollDistance]="2" 
    [infiniteScrollThrottle]="50" 
    (scrolled)="onScroll()" 
    [scrollWindow]="false">  
    <div *ngFor="let card of lazy">  
        <div [class]=" card.Legal ? 'card-box legal-card clearfix' : 'card-box illegal-card clearfix' " style="height: 100%;">
            <img src="{{ card.ImageUrl }}" (click)="onCardFaceClick(card)" width="146" height="204"
                style="float: left;padding: 5px;margin-right: 0.75rem; cursor: pointer;">
            <!-- width="204" height="286" -->
            <div>
                <div style="width: 100%; ">
                    <strong>{{ card.Name }}</strong> 
                    <span style="float: right; text-align: right;">
                        <pre [innerHTML]="iconifyText(card.ManaCost)" style="margin: 1px;"></pre>
                        <br/>
                        <span style="width: 100%;" >
                            <i class="fa fa-lg link-icon fa-external-link" aria-hidden="true"
                                (click)="onLinkClick(card)"></i>
                            <i aria-hidden="true" 
                                [class]="deckBuilderService.CardIsInDeck(card) ? 'fa fa-lg link-icon fa-star' : 'fa fa-lg link-icon fa-star-o'"
                                (click)="onDeckListClick(card)"></i>
                        </span> <br/>
                        <span style="width: 100%;" >
                            <i *ngIf="card.LegalCombos > 0"
                                aria-hidden="true"
                                style="color: green"
                                class="fa fa-lg link-icon fa-list" 
                                (click)="onCardFaceClick(card)">
                            </i>
                            <i
                                *ngIf="card.BannedCombos > 0"
                                aria-hidden="true" 
                                style="color: red"
                                class="fa fa-lg link-icon fa-list"
                                (click)="onCardFaceClick(card)">
                            </i>
                        </span>
                    </span>
                </div>                
                <div><em>{{ card.SubType ? card.Type + " - " + card.SubType : card.Type }}</em></div>
                <div style="white-space: pre-wrap; " [innerHTML]="iconifyText(card.OracleText)"></div>
                <div *ngIf="card.Power != null || card.Toughness != null"> 
                    <strong>{{ card.Power }} / {{ card.Toughness }} </strong>                         
                </div>
            </div>
        </div>
    </div>
</div>
