import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Card } from '../model/Card';
import { ToolsService } from './tools.service';
import { Utils } from '../model/Utils';
import { ComboData } from '../model/ComboData';
import { Combo } from '../model/Combo';

@Component({
  selector: 'app-card-combo',
  templateUrl: './card-combo.component.html',
  styleUrls: ['./card-combo.component.css']
})
export class CardComboComponent {
    @Input() Combo: Combo;
    @Input() Cards: Card[];

    @Output() CardClicked = new EventEmitter<Card>();

    public GetDescription(combo: Combo): string {
        return Utils.IconifyText(combo.Description);
    }

    public onCardFaceClick(oracleId: string) {
        let card: Card = this.Cards.find( c => c.OracleId === oracleId );
        this.CardClicked.emit(card);
    }

    public getCardImage(oracleId: string): string {
        return this.Cards.find( c => c.OracleId === oracleId ).ImageUrl;
    }

    public getComboTitle(combo: Combo): string {
        return combo.Cards.map( id => this.Cards.find( c => c.OracleId === id).Name).join(' + ');
    }
}
