import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of, ObservableInput } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Card } from './model/Card';
import { DbResult } from './model/DbResult';
import { QueryParams } from './model/QueryParams';
import { ScryFallList, ScryFallRuling } from './model/ScryFall';
import { IMatchResult } from './model/Match';
import { ComboData } from './model/ComboData';

const httpOptions = {
  
};

@Injectable()
export class DatabaseService {

  protected get apiUrl(): string {
    return 'https://mtgLeague.prodere.com/cgi-bin/mtg.cgi';
  }

  protected jsonPostOptions = {headers: {'Content-Type': 'application/json'}};

  protected get headers(): HttpHeaders {
    return new HttpHeaders();
  }

  constructor(private http: HttpClient) {

  }

  public GetCards(queryParams: QueryParams): Observable<DbResult<Card>> {
    let params = new HttpParams().set('target', 'query');

    params = queryParams.SetHttpParams(params);

    return this.http.get<DbResult<Card>>(this.apiUrl, {headers: this.headers, params: params});
  }

  public GetUncostedCards(): Observable<DbResult<Card>> {
    return this.http.get<DbResult<Card>>(`${this.apiUrl}?target=uncosted`, {headers: this.headers});
  }

  public GetCardsWindow(start: number, count: number, queryParams: QueryParams): Observable<DbResult<Card>> {
    let params = new HttpParams()
      .set('target', 'query')
      .set('windowStart', start.toString())
      .set('windowCount', count.toString());

    params = queryParams.SetHttpParams(params);

    return this.http.get<DbResult<Card>>(this.apiUrl, {headers: this.headers, params: params})
      .pipe( catchError( this.handleServiceError ) );
  }

  public CheckDeckList(cardNames: string[]): Observable<Card[]> {
    const bodyContent: string = JSON.stringify(cardNames);
    return this.http.post<Card[]>(`${this.apiUrl}?target=deckcheck`, bodyContent, this.jsonPostOptions)
      .pipe( catchError( this.handleServiceError ) );
  }

  public CardIdLookup(cardIds: string[]): Observable<Card[]> {
    const bodyContent: string = JSON.stringify(cardIds);
    return this.http.post<Card[]>(`${this.apiUrl}?target=idlookup`, bodyContent, this.jsonPostOptions)
      .pipe( catchError( this.handleServiceError ) );
  }

  public CardOracleIdLookup(cardIds: string[]): Observable<Card[]> {
    const bodyContent: string = JSON.stringify(cardIds);
    return this.http.post<Card[]>(`${this.apiUrl}?target=oraclelookup`, bodyContent, this.jsonPostOptions)
      .pipe( catchError( this.handleServiceError ) );
  }

  public QueryCardRulings(card: Card): Observable<ScryFallList<ScryFallRuling>> {
    return this.http.get<ScryFallList<ScryFallRuling>>(card.RulingsUri)
      .pipe( catchError( this.handleServiceError ) );
  }

  public GetSeasonStandings(): Observable<IMatchResult[]> {
    // 'https://mtgleague.prodere.com/data/seasonMatches.json'
    return this.http.get<IMatchResult[]>('https://mtgLeague.prodere.com/cgi-bin/standings.cgi')
      .pipe( catchError( this.handleServiceError ) );
  }

  public GetSeason3Delta(): Observable<DbResult<Card>> {
    return this.http.get<DbResult<Card>>('https://mtgLeague.prodere.com/cgi-bin/delta4.cgi');
  }

  public GetLegalCombos(): Observable<ComboData> {
    return this.http.get<ComboData>(`${this.apiUrl}?target=legalCombos`, {headers: this.headers});
  }

  public GetComboDataForCard(oracleId: string): Observable<ComboData> {
    return this.http.get<ComboData>(`${this.apiUrl}?target=combo&id=${oracleId}`, {headers: this.headers});
  }

  protected handleServiceError<T>( err: any, caught: Observable<T> ): ObservableInput<T> {
    alert(err);
    return of();
  }
}