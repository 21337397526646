import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DbResult } from '../model/DbResult';
import { Card } from '../model/Card';
import { LazyLoadEvent, MessageService, Message } from 'primeng/api';
import { Utils } from '../model/Utils';
import { DeckBuilderService } from './deck-builder.service';
import { DatabaseService } from '../database.service';
import { QueryParams } from '../model/QueryParams';
import { ToolsService } from './tools.service';
import { NgModel } from '@angular/forms';




@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.css']
})
export class CardListComponent {
    lazy: Card[] = [];
    totalRecords: number = 0;
    page: number = 0;

    @Input() public set lazyCards(result: DbResult<Card>) {
      if ( result != null ) {
        this.lazy = result.Records.filter(x => x != null);
        this.totalRecords = result.TotalRecords;
      }
    }

    @Input() queryParams: QueryParams;
    @Output() public lazyLoad = new EventEmitter<LazyLoadEvent>();

    constructor(
      public deckBuilderService: DeckBuilderService,
      public messageService: MessageService,
      public databaseService: DatabaseService,
      public toolsService: ToolsService ) {
    }

    iconifyText(text: string): string {
      return Utils.IconifyText(text);
    }

    onDeckListClick( card: Card ) {
      this.deckBuilderService.onDeckListClick(card);
    }

    onLinkClick( card: Card ) {
      this.onCardFaceClick(card);
    }

    onCardFaceClick(card: Card) {
      this.toolsService.activeCard = card;
      this.toolsService.showCardDetails = true;
    }

    private replaceAll(target: string, search: string, replacement: string): string {
      return target.replace(new RegExp(search, 'g'), replacement);
    }

    trackByFunction( index, item ) {
      return index;
    }


    public runSearch() {
      this.lazy.length = 0;
      this.page = 0;

      this.onScroll();
    }

    onScroll() {
      const page = this.page++;
      const window = 50;

      if ( page === 0 || page < Math.ceil(this.totalRecords / window) ) {
        this.databaseService.GetCardsWindow(page * window, window, this.queryParams)
          .subscribe( result => this.mergeCards(result, page, window ) );
      }
    }

    mergeCards( result: DbResult<Card>, page: number, rowsPerPage: number ) {
      this.totalRecords = result.TotalRecords;

      this.lazy.length =  Math.max( this.lazy.length, (page * rowsPerPage) + result.Records.length );
      const first = page * rowsPerPage;

      for (let i = 0; i < result.Records.length; ++i) {
        if (result.Records[i] != null ) {
          this.lazy[i + first] = result.Records[i];
        }
      }
    }
}

