import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DatabaseService } from '../database.service';
import { DbResult } from '../model/DbResult';
import { Card } from '../model/Card';
import { MessageService, Message } from 'primeng/api';
import { ToolsService } from './tools.service';
import { DeckBuilderService } from './deck-builder.service';
import { Deck, DeckEntry } from '../model/Deck';
import { Utils } from '../model/Utils';
import {  } from 'protractor';


@Component({
  selector: 'app-deck-editor-entry',
  templateUrl: './deck-editor-entry.component.html',
  styleUrls: ['./deck-editor-entry.component.css']
})
export class DeckEditorEntryComponent {

    @Input() card: Card;
    @Input() deckEntry: DeckEntry;
    @Output() deckChanged: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private databaseService: DatabaseService,
        private toolsService: ToolsService,
        private messageService: MessageService,
        private deckBuilderService: DeckBuilderService) {
    }

    iconifyText(text: string): string {
      return Utils.IconifyText(text);
    }


    public onMainBoardRemoveClick() {
      if (this.deckEntry.mainCount > 0 ) {
        this.deckEntry.mainCount--;
        this.onDeckChanged();
      }
    }

    public onMainBoardAddClick() {
      if (this.deckEntry.mainCount < 4 || this.card.Type === 'Basic Land') {
        this.deckEntry.mainCount++;
        this.onDeckChanged();
      }
    }

    public onSideBoardRemoveClick() {
      if (this.deckEntry.sideCount > 0 ) {
        this.deckEntry.sideCount--;
        this.onDeckChanged();
      }
    }

    public onSideBoardAddClick() {
      if (this.deckEntry.sideCount < 4 || this.card.Type === 'Basic Land') {
        this.deckEntry.sideCount++;
        this.onDeckChanged();
      }
    }

    public onRemoveFromDeckClick() {
      if (this.deckEntry.mainCount === 0 && this.deckEntry.sideCount === 0) {
        this.deckBuilderService.TryRemoveDeckEntry(this.deckEntry);
        this.onDeckChanged();
      }
    }

    public onDeckChanged() {
      this.deckChanged.emit();
    }

    onCardFaceClick(card: Card) {
      this.toolsService.activeCard = card;
      this.toolsService.showCardDetails = true;
    }
}
