<p-panel [toggleable]="false">
    <p-header>
        <div class="p-grid" style="padding-top: 0.75em;"> 
            <div class="p-col-8" >
                <span class="ui-float-label ui-fluid">
                    <input id="float-input" type="text" pInputText [style]="{'width':'100%'}" [(ngModel)]="queryParams.SearchString"
                        (keyup)="textKeyUp($event)"> 
                    <label for="float-input">Card Name</label>
                </span>
            </div> 

            <div class="p-col-4 wide-p-button">
                <p-button label="Search" (onClick)="searchClicked($event)" 
                    [style]="{'width':'100%'}" icon="fa fa-search" class="ui-button-raised">
                </p-button>
            </div>

            <div class="p-col-8" style="padding-top: 0.75em;">
                <span class="ui-float-label ui-fluid">
                    <input id="float-input" type="text" pInputText [style]="{'width':'100%'}" [(ngModel)]="queryParams.TextSearch"
                        (keyup)="textKeyUp($event)">                        
                    <label for="float-input">Oracle Text</label>
                </span>
            </div> 

            <div class="p-col-4 wide-p-button">
                <p-button label="Reset" (onClick)="resetClicked($event)" 
                    [style]="{'width':'100%'}" icon="fa fa-undo"
                    styleClass="ui-button-secondary">
                </p-button>
            </div>
        </div>
    </p-header>

    <div class="p-grid ui-fluid wide-p-dropdown">
        <div class="p-col-12 p-sm-6">
            <div style="display: flex; justify-content: space-around;">
                <span>
                    <p-checkbox name="groupColors" value="W" [(ngModel)]="queryParams.Colors" [style]="{'padding-right': '2rem'}"> </p-checkbox> <i class="ms ms-w ms-cost ms-shadow"></i>
                </span>
                <span>
                    <p-checkbox name="groupColors" value="U" [(ngModel)]="queryParams.Colors" [style]="{'padding-right': '2rem'}"> </p-checkbox> <i class="ms ms-u ms-cost ms-shadow"></i>
                </span>
                <span>
                    <p-checkbox name="groupColors" value="B" [(ngModel)]="queryParams.Colors" [style]="{'padding-right': '2rem'}"> </p-checkbox> <i class="ms ms-b ms-cost ms-shadow"></i>
                </span>
                <span>
                    <p-checkbox name="groupColors" value="R" [(ngModel)]="queryParams.Colors" [style]="{'padding-right': '2rem'}"> </p-checkbox> <i class="ms ms-r ms-cost ms-shadow"></i>
                </span>
                <span>
                    <p-checkbox name="groupColors" value="G" [(ngModel)]="queryParams.Colors" [style]="{'padding-right': '2rem'}"> </p-checkbox> <i class="ms ms-g ms-cost ms-shadow"></i>
                </span>
            </div>
        </div>
        <div class="p-col-12 p-sm-6">
            <div style="display: flex; justify-content: space-around;">
                <span>
                    <p-checkbox [(ngModel)]="queryParams.ExcludeUnselectedColors" binary="true" label="Exclude Unselected Colors" >                         
                    </p-checkbox>
                </span>
                <span>
                    <p-checkbox [(ngModel)]="queryParams.RequireAllColors" binary="true" label="Require All Selected Colors" >                         
                    </p-checkbox>
                </span>
            </div>
        </div>

        <div class="p-col-12 p-md-6">
            <div class="ui-inputgroup">
                <span class="ui-inputgroup-addon" style="padding-right: 1.0rem;">Sort&nbsp;Order:&nbsp;</span>
                <p-dropdown [options]="orderOptions" [(ngModel)]="queryParams.Sort" [style]="{'width':'100%'}"
                    (keyup)="textKeyUp($event)">
                </p-dropdown>
            </div>
        </div>
        <div class="p-col-12 p-md-6">
            <div class="ui-inputgroup">
                <span class="ui-inputgroup-addon" style="padding-right: 1.0rem;">Type:&nbsp;</span>
                <p-dropdown [options]="typeOptions" [editable]="true" [(ngModel)]="queryParams.Type" [style]="{'width': '100%'}"
                    (keyup)="textKeyUp($event)">
                </p-dropdown>
            </div>
        </div>
        <div class="p-col-12 p-md-6">
            <div class="ui-inputgroup">
                <span class="ui-inputgroup-addon" style="padding-right: 1.0rem;">Sub&nbsp;Type:&nbsp;</span>
                <input [(ngModel)]="queryParams.SubType" type="text" pInputText (keyup)="textKeyUp($event)"> 
            </div>
        </div>

        <div class="p-col-12 p-md-6">
            <app-input-slider [min]="0" [max]="16" [(range)]="queryParams.CmcRange" label="CMC:">                
            </app-input-slider>
        </div>
        
        <div class="p-col-12 p-md-6">
            <app-input-slider [min]="-1" [max]="16" [(range)]="queryParams.PowerRange" label="Power:">                
            </app-input-slider>
        </div>

        <div class="p-col-12 p-md-6">
            <app-input-slider [min]="-1" [max]="16" [(range)]="queryParams.ToughnessRange" label="Toughness:">                
            </app-input-slider>
        </div>

        <div class="p-col-12 p-md-6 wide-p-toggleButton">
            <p-toggleButton [(ngModel)]="queryParams.LegalOnly" onLabel="Legal Cards Only" offLabel="All Cards"
                onIcon="fa fa-gavel" offIcon="fa fa-globe">
            </p-toggleButton>
        </div>
    </div>
    
</p-panel>