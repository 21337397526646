import { Injectable } from '@angular/core';
import { Card } from '../model/Card';

@Injectable()
export class ToolsService {
    showToolSidebar: boolean = false;
    maximizeContent: boolean = false;

    showUncostedListDialog: boolean = false;
    showDeckInputDialog: boolean = false;
    showDeckTextDialog: boolean = false;
    showDelta3List: boolean = false;

    showCardDetails: boolean = false;
    public activeCard: Card = null;

    activeDialog: Dialog = Dialog.CardBrowser;

    public get showCardBrowser(): boolean {
        return this.activeDialog === Dialog.CardBrowser;
    }

    public get showDeckEditor(): boolean {
        return this.activeDialog === Dialog.DeckEditor;
    }

    public get showDeckValidator(): boolean {
        return this.activeDialog === Dialog.DeckValidator;
    }

    public get showLeaderboard(): boolean {
        return this.activeDialog === Dialog.Leaderboard;
    }

}

export enum Dialog {
    CardBrowser = 1,
    DeckEditor,
    DeckValidator,
    Leaderboard,
}
