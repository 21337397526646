import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DatabaseService } from '../database.service';
import { DbResult } from '../model/DbResult';
import { Card } from '../model/Card';
import { MessageService, Message } from 'primeng/api';
import { ToolsService } from './tools.service';
import { DeckBuilderService } from './deck-builder.service';
import { Utils, Dictionary } from '../model/Utils';
import { ScryFallRuling, ScryFallList } from '../model/ScryFall';
import { ComboData } from '../model/ComboData';



@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.css']
})
export class CardDetailsComponent {

  // tslint:disable-next-line: variable-name
  private _card: Card = null;

  @Input() public set card(value: Card) {
    this.rulings = null;
    this.hideRulings = true;
    this.hideCombos = true;
    this._card = value;
    this.ComboData = null;
  }
  public get card(): Card {
    return this._card;
  }

  rulings: ScryFallRuling[];
  hideRulings: boolean = true;
  hideCombos: boolean = true;
  ComboData: ComboData;

  constructor(
      private databaseService: DatabaseService,
      public toolsService: ToolsService,
      private messageService: MessageService,
      public deckBuilderService: DeckBuilderService) {
  }

  iconifyText(text: string): string {
    return Utils.IconifyText(text);
  }

  onShowCardDetails(event: any) {
  }

  onHideCardDetails(event: any) {
  }

  onCardClicked(card: Card) {
    this.toolsService.activeCard = card;
  }

  onLoadRulings() {
    this.databaseService.QueryCardRulings(this.card).subscribe( payload => this.readRulingsData(payload) );
  }

  onLoadCombos() {
    if (this.card != null ) {
      this.databaseService.GetComboDataForCard(this.card.OracleId).subscribe( payload => this.ComboData = payload );
    }
  }

  comboCount(): number {
    return this.card.BannedCombos + this.card.LegalCombos;
  }

  readRulingsData(result: ScryFallList<ScryFallRuling> ) {
    const dictionary = new Dictionary<ScryFallRuling>(result.data);
    this.rulings = dictionary.values().filter( v => v as ScryFallRuling );
  }

  onDeckListClick( card: Card ) {
    this.deckBuilderService.onDeckListClick(card);
  }
}
